export const DEFAULT_PAGE_INDEX = 1
export const DEFAULT_PAGE_SIZE = 50
export const DEFAULT_ARRAY_LENGTH = 0
export const DEFAULT_PROP_DESCRIPTION_ROW = 5
export const MIN_PASSWORD_LENGTH = 6
export const DEFAULT_NUMBER_USER = 1
export const MAX_NUMBER_USER = 10000
export const MIN_NAME_LENGTH = 11
export const MIN_PREFIX_LENGTH = 4
export const MAX_PREFIX_LENGTH = 8
export const DEFAULT_HOUR_ADDED = 4
export const DEFAULT_DAY_ADDED = 1
export const DEFAULT_TOTAL = 0

// datetime
export const DEFAULT_FORMAT_DATE_TIME = 'DD-MM-YYYY  HH:mm:ss'

export const DEFAULT_FORMAT_DATE = 'DD-MM-YYYY'

export const DEFAULT_FORMAT_DATE_EN = 'MM.DD.YYYY'

export const DEFAULT_FORMAT_DATE_SGN = 'DD/MM/YY hh:mm a'

export const DEFAULT_FORMAT_SHORT_DATE = 'MM/DD/YYYY'

export const DEFAULT_ISO_FORMAT_DATE = 'YYYY-MM-DD'

export const DEFAULT_FORMAT_HOURS = 'HH:mm a'

export const DEFAULT_FORMAT_UTC_DATE_TIME = 'MM/DD/YYYY HH:mm a'

//sort
export const ASCEND = 'ascend'

export const DESCEND = 'descend'

export const CREATED_AT = 'createdAt'

export const UPDATED_AT = 'updatedAt'

// url download
export const DOWNLOAD_URL = `${process.env.REACT_APP_BACK_END_URL}/download/series/`

// url delete
export const DELETE_URL = `${process.env.REACT_APP_BACK_END_URL}/remove/`
