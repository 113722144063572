import React from 'react'

export default {
  formatNumber(number, nullable) {
    if (number > 0) {
      return number
    }
    if (!nullable) {
      return 0
    }
    return null
  },
  roundDecimalPlace (num, decimal, defaultNumber) {
    const pow = Math.pow(10, decimal ? decimal : 0)
    return num ? Math.round(num * pow) / pow : defaultNumber || 0
  },
}
