import React from 'react'
import {
  UserOutlined,
  FileOutlined,
  ShoppingOutlined,
  ExclamationCircleOutlined,
  TeamOutlined,
  UnlockOutlined,
  HistoryOutlined
} from '@ant-design/icons'
import { routes, resources } from '../routes'

export default [
  {
    name: resources.USER_LIST.title,
    key: resources.USER_LIST.title,
    resource: resources.USER_LIST.key,
    path: routes.USER_LIST,
    icon: <UserOutlined />,
  },
  {
    name: resources.DEALER.title,
    key: resources.DEALER.title,
    resource: resources.DEALER.key,
    path: routes.DEALER,
    icon: <TeamOutlined />,
  },
  {
    name: resources.RESET_PASSWORD.title,
    key: resources.RESET_PASSWORD.title,
    resource: resources.RESET_PASSWORD.key,
    path: routes.RESET_PASSWORD,
    icon: <HistoryOutlined />,
  },
  {
    name: resources.FILE.title,
    key: resources.FILE.title,
    resource: resources.FILE.key,
    path: routes.FILE,
    icon: <FileOutlined />,
  },
  {
    name: resources.ORDER.title,
    key: resources.ORDER.title,
    resource: resources.ORDER.key,
    path: routes.ORDER,
    icon: <ShoppingOutlined />,
  },
  {
    name: resources.BLACKLIST.title,
    key: resources.BLACKLIST.title,
    resource: resources.BLACKLIST.key,
    path: routes.BLACKLIST,
    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  },
]
