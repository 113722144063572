import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import PageAuthorization from '../components/PageAuthorization'

// components

// routes

const ProtectedRoute = ({
  component: Component,
  resource,
  action,
  auth,
  config,
  isMobile,
  ...rest
}) => {
  const [keycloak] = useKeycloak()
  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak.authenticated ? (
          <Component {...props} />
        ) : (
          <PageAuthorization />
        )
      }
    />
  )
}

export default withRouter(ProtectedRoute)
