export const PAGE_USER = 'Users'
export const PAGE_USER_SESSION = 'Users Session'
export const PAGE_FILE = 'Files'
export const PAGE_ORDER = 'Orders'
export const PAGE_BLACKLIST = 'Blacklist'
export const PAGE_DEALER = 'Dealer'
export const PAGE_RESETPASSWORD = 'Reset Password'
export const SINGLE_USER = 'user'
export const SINGLE_SESSION = 'session'
export const SINGLE_FILE = 'file'
export const SINGLE_ORDER = 'order'
export const SINGLE_PRODUCT = 'product'
export const SINGLE_BLACKLIST = 'blacklist'
export const SINGLE_DEALER = 'dealer'
export const SINGLE_RESETPASSWORD = 'resetPassword'

export const BUTTON_CREATE = 'Create'
export const BUTTON_CREATE_MANY = 'Create Many'
export const BUTTON_CANCEL = 'Cancel'
export const BUTTON_SAVE = 'Save'
export const BUTTON_DELETE = 'Delete Selected User'
export const BUTTON_BLOCK = 'Block'
export const BUTTON_UN_BLOCK = 'UnBlock'
export const BUTTON_RESTORE = 'Restore'
export const BUTTON_EDIT = 'Edit'
export const BUTTON_LOGIN = 'Login'
export const BUTTON_RESET_PASSWORD = 'Change Password'

export const COL_INDEX = 'Index'
export const COL_NAME = 'Key'
export const COL_EMAIL = 'Email'
export const COL_DEALER = 'Dealer'
export const COL_ACCOUNT_TYPE = 'Type'
export const COL_USER_PREFIX = 'Prefix'
export const COL_USERID = 'UserId'
export const COL_TIME_ADDED = 'Time'
export const COL_PROMOTION = 'Ads'
export const COL_SELLY = 'Selly'
export const COL_ACTIVE = 'Used'
export const COL_IS_TIME_ADDED = 'Extended'
export const COL_IS_PROMOTION = 'isPromotion'
export const COL_BLOCK = 'Block'
export const COL_TOTAL_LOGIN = 'Total'
export const COL_EXPIRED = 'Expired'
export const COL_FIRST_LOGIN = 'FirstLogin'
export const COL_LAST_LOGIN = 'LastLogin'
export const COL_CREATED_AT = 'Logged'
export const COL_UPDATED_AT = 'Updated'
export const COL_ACTION = 'Action'
export const COL_EXPIRED_AT = 'Expired'
export const COL_FIRST_LOGIN_AT = 'First Login'
export const COL_FAIL_TYPE = 'Fail Type'
export const COL_BLOCKED_DATA = 'Blocked Data'
export const COL_BLACKLIST_TYPE = 'Blacklist Type'
export const COL_BLACKLIST_ID = 'Blacklist Selly Id'
export const LABEL_NAME = 'Name'
export const LABEL_PREFIX = 'Prefix'
export const LABEL_PARENT = 'Parent'
export const LABEL_EMAIL = 'Email'
export const LABEL_PASSWORD = 'Password'
export const LABEL_ACTIVE_STATUS = 'Active'
export const LABEL_BLOCK_STATUS = 'Block'
export const LABEL_PROMOTION_STATUS = 'Ads'
export const LABEL_ACCOUNT_TYPE = 'Account Type'
export const LABEL_ACCOUNT_ADD_TIME = 'Add More Time'
export const LABEL_NUMBER_OF_USERS = 'Number Of Users'
export const LABEL_CHANGE_PASSWORD = 'Change Password'
export const LABEL_RETYPE_PASSWORD = 'Retype Password'
export const LABEL_RESET_PASSWORD = 'Reset Password'
export const LABEL_TOTAL = 'Total'
export const LABEL_SUCCESS = 'Success'
export const LABEL_FAIL = 'Fail'

export const COL_USERNAME = 'Username'
export const COL_RESET_HARDWARE = 'Reset'
export const COL_SESSION_ID = 'SessionID'
export const COL_IP = 'IP'
export const COL_COUNTRY_CODE = 'Country'
export const COL_CPU_ID = 'CPUID'
export const COL_HARDWARE_ID = 'HardwareID'
export const COL_TICK_DECIMAL = 'TickDecimal'
export const COL_SERVER_IP = 'ServerIp'
export const COL_SERVER_COUNTRY_CODE = 'Server'
export const COL_PC_USER = 'PcUser'
export const COL_PC_NAME = 'PcName'
export const COL_PC_OS = 'PcOS'
export const COL_REQUEST = 'Request'
export const COL_RESPONSE = 'Response'
export const COL_SUCCESS = 'Success'
export const COL_EXTENDED = 'Extended'
export const COL_STATUS = 'Status'

export const COL_FILENAME = 'File Name'
export const COL_USER_COUNT = 'User Count'

export const COL_ORDER = 'OrderId'
export const COL_BLACKLIST = 'BlacklistId'
export const COL_DEALER_ID = 'DealerId'
export const COL_RESETPASSWORD = 'Reset Password Id'
export const COL_PRODUCT_TYPE = 'Product Type'
export const COL_GATEWAY = 'Gateway'
export const COL_VALUE = 'Value'
