import mainRoute from './mainRoute'
import LoadableComponent from '../components/LoadableComponent'

export const routes = {
  /**
   * USER
   */
  USER_LIST: `${mainRoute.USER}`,
  USER_EDIT: `${mainRoute.USER}/:id`,
  USER_CREATE: `${mainRoute.USER}/create`,
  USER_SESSION: `${mainRoute.USER}/session/:id`,
  /**
   * END USER
   */
  /**
   * FILE
   */
  FILE: `${mainRoute.FILE}`,
  // ORDER
  ORDER: `${mainRoute.ORDER}`,
  ORDER_DETAIL: `${mainRoute.ORDER}/:id`,
  // BLACKLIST
  BLACKLIST: `${mainRoute.BLACKLIST}`,

  // DEALER
  DEALER: `${mainRoute.DEALER}`,
  DEALER_CREATE: `${mainRoute.DEALER}/create`,

  //RESET PASSWORD
  RESET_PASSWORD: `${mainRoute.RESETPASSWORD}`,

  LOGIN: `${mainRoute.AUTHORIZATION}/login`,
  HOME_PAGE: `${mainRoute.HOME_PAGE}`,
}

export const resources = {
  LOGIN: {
    title: 'Login',
    key: 'LOGIN',
  },

  // user
  USER_LIST: {
    title: 'Users',
    key: 'USER_LIST',
  },
  USER_EDIT: {
    title: 'Edit User',
    key: 'USER_EDIT',
  },
  USER_CREATE: {
    title: 'Create User',
    key: 'USER_CREATE',
  },
  USER_SESSION: {
    title: 'Session User',
    key: 'USER_SESSION',
  },
  FILE: {
    title: 'Files',
    key: 'Files',
  },
  ORDER: {
    title: 'Orders',
    key: 'Orders',
  },
  ORDER_DETAIL: {
    title: 'Order Detail',
    key: 'ORDER_DETAIL',
  },
  BLACKLIST: {
    title: 'Blacklists',
    key: 'Blacklists',
  },
  DEALER: {
    title: 'Dealers',
    key: 'Dealers',
  },
  DEALER_CREATE: {
    title: 'Create Dealer',
    key: 'DEALER_CREATE',
  },
  RESET_PASSWORD: {
    title: 'Reset Password',
    key: 'RESET_PASSWORD',
  },
}

export const navigation = [
  // {
  //   name: resources.LOGIN.title,
  //   key: resources.LOGIN.key,
  //   resource: resources.LOGIN.key,
  //   path: routes.LOGIN,
  //   component: LoadableComponent(() => import('../views/Authorization/Login'))
  // },
  {
    name: resources.USER_CREATE.title,
    key: resources.USER_CREATE.key,
    resource: resources.USER_CREATE.key,
    path: routes.USER_CREATE,
    component: LoadableComponent(() => import('../views/User/create')),
  },
  {
    name: resources.USER_SESSION.title,
    key: resources.USER_SESSION.key,
    resource: resources.USER_SESSION.key,
    path: routes.USER_SESSION,
    component: LoadableComponent(() => import('../views/User/Session')),
  },
  {
    name: resources.USER_EDIT.title,
    key: resources.USER_EDIT.key,
    resource: resources.USER_EDIT.key,
    path: routes.USER_EDIT,
    component: LoadableComponent(() => import('../views/User/edit')),
  },
  {
    name: resources.USER_LIST.title,
    key: resources.USER_LIST.key,
    resource: resources.USER_LIST.key,
    path: routes.USER_LIST,
    component: LoadableComponent(() => import('../views/User')),
  },
  {
    name: resources.FILE.title,
    key: resources.FILE.key,
    resource: resources.FILE.key,
    path: routes.FILE,
    component: LoadableComponent(() => import('../views/File')),
  },
  {
    name: resources.ORDER_DETAIL.title,
    key: resources.ORDER_DETAIL.key,
    resource: resources.ORDER_DETAIL.key,
    path: routes.ORDER_DETAIL,
    component: LoadableComponent(() => import('../views/Order/detail')),
  },
  {
    name: resources.ORDER.title,
    key: resources.ORDER.key,
    resource: resources.ORDER.key,
    path: routes.ORDER,
    component: LoadableComponent(() => import('../views/Order')),
  },
  {
    name: resources.BLACKLIST.title,
    key: resources.BLACKLIST.key,
    resource: resources.BLACKLIST.key,
    path: routes.BLACKLIST,
    component: LoadableComponent(() => import('../views/Blacklist')),
  },
  {
    name: resources.DEALER_CREATE.title,
    key: resources.DEALER_CREATE.key,
    resource: resources.DEALER_CREATE.key,
    path: routes.DEALER_CREATE,
    component: LoadableComponent(() => import('../views/Dealer/create')),
  },
  {
    name: resources.DEALER.title,
    key: resources.DEALER.key,
    resource: resources.DEALER.key,
    path: routes.DEALER,
    component: LoadableComponent(() => import('../views/Dealer')),
  },
  {
    name: resources.RESET_PASSWORD.title,
    key: resources.RESET_PASSWORD.key,
    resource: resources.RESET_PASSWORD.key,
    path: `${routes.RESET_PASSWORD}`,
    component: LoadableComponent(() => import('../views/ResetPassword')),
  },
  {
    path: routes.LOGIN,
    component: LoadableComponent(() => import('../views/Authorization/Login')),
    unProtected: true,
  },
  {
    path: routes.HOME_PAGE,
    component: LoadableComponent(() =>
      import('../components/PageAuthorization')
    ),
    unProtected: true,
  },
]
