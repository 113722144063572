const USER = 'user'
const FILE = 'file'
const ORDER = 'order'
const BLACKLIST = 'blacklist'
const DEALER = 'dealer'
const AUTHORIZATION = 'authorization'
const RESETPASSWORD = 'reset-password'

export default {
  HOME_PAGE: '/',
  USER: `/${USER}`,
  FILE: `/${FILE}`,
  ORDER: `/${ORDER}`,
  BLACKLIST: `/${BLACKLIST}`,
  DEALER: `/${DEALER}`,
  RESETPASSWORD: `/${RESETPASSWORD}`,
  AUTHORIZATION: `/${AUTHORIZATION}`,
}
