import React, { useCallback } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { withKeycloak } from '@react-keycloak/web'
import classNames from 'classnames'
// components
import ButtonPermission from './Button/ButtonPermission'
import { BUTTON_LOGIN } from '../constants/label'
import { ACTION_RBAC } from '../constants/enumType'

const PageAuthorization = withRouter(
  withKeycloak(({ keycloak, location }) => {
    const { from } = location.state || { from: { pathname: '/user' } }
    console.log('login keycloak.authenticated...', location.state)
    if (keycloak.authenticated) return <Redirect to={from} />

    const login = useCallback(() => {
      keycloak.login()
    }, [keycloak])

    return (
      <div>
        <h1 className="text-uppercase text-center">
          <strong>
            LOL ADMIN LOGIN
          </strong>
        </h1>
        <div className={classNames('inner text-center')}>
          <h4 className="text-uppercase mb-5">
            <strong>Please log in</strong>
          </h4>
          <ButtonPermission
            buttonName={BUTTON_LOGIN}
            action={ACTION_RBAC.WRITE}
            onClick={login}
            type='primary'
            className="w__150"
          /> 
        </div>
      </div>
    )
  })
)

export default PageAuthorization
