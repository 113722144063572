import { reducer as notifications } from 'react-notification-system-redux'
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { reducer as modal } from 'redux-modal'
import data from './data'
import product from './product'
import fetching from './fetching'

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    data,
    product,
    modal,
    fetching
  })

export default rootReducer