export const ACTION_RBAC = {
  WRITE: 'Write',
  READ: 'Read',
  NO_CONTROL: 'NoControl',
}

export const ACTION_CRUD = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
}

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
}

export const CONFIRM_TYPE = {
  DELETE: 'delete',
  PUBLISH: 'publish',
  HIDE: 'hide',
  RESTORE: 'restore',
  REMOVE: 'remove',
  RESET: 'reset',
  LOCK: 'lock',
  BLOCK: 'block',
  UNBLOCK: 'unBlock',
  ACTIVE:'active'
}

export const STATUS = {
  ACTIVE: true,
  DELETE: false,
}

export const STATUS_BLOCK = {
  BLOCK: true,
  UNBLOCK: false,
}
export const STATUS_TIME_ADDED = {
  ADDED: true,
  NOTADDED: false,
}

export const STATUS_PROMOTION = {
  YES: true,
  NO: false,
}

export const STATUS_LOGIN = {
  SUCCESS: true,
  FAILED: false,
}

export const INPUT_TYPE = {
  INPUT: 'input',
  NUMBER: 'number',
  SELECT: 'select',
}

export const ACCOUNT_TYPE = {
  HOUR: 'Hour',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
}

export const BLOCK_ACCOUNT = {
  BLOCK: true,
  UNBLOCK: false,
}

export const ACTIVE_ACCOUNT = {
  ACTIVE: true,
  DEACTIVE: false,
}

export const NUMBER_OF_USERS = {
  TEN: 10,
  TWENTY: 20,
  FIFTY: 50,
  ONE_HUNDRED: 100,
  TWO_HUNDRED: 200,
  A_THOUSAND: 1000,
}

export const MODE = {
  EDIT: 'edit',
  CREATE: 'create',
  DETAIL: 'detail',
}

export const ORDER_STATUS = {
  NOT_PAID: 0,
  REVERSED: 51,
  HIGH_RISK: 52,
  PARTIAL: 53,
  CONFIRMING: 54,
  PENDING: 55,
  REFUNDED: 56,
  COMPLETED: 100,
}

export const BLACKLIST_TYPE = {
  EMAIL: 'email',
  IP_ADDRESS: 'ip_address',
}
