import React from 'react'
// lib
import Button from 'antd/lib/button'
import PropTypes from 'prop-types'
// hoc
import { withPermission } from '../../hocs/withPermission'

const ButtonLink = (props) => {
  const { buttonName } = props

  return <Button {...props}>{buttonName}</Button>
}

ButtonLink.propTypes = {
  buttonName: PropTypes.any
}

export default withPermission(ButtonLink)
