import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/es/integration/react'
import { KeycloakProvider } from '@react-keycloak/web'
import { Helmet } from 'react-helmet'

import configStore from './configStore'
import Layout from './components/Layout'

import configureKeycloak from './configure-keycloak'

const title = process.env.REACT_APP_TITLE || 'admin'

const keycloakProviderInitConfig = {
  onLoad: 'check-sso'
}

const onKeycloakEvent = (event, error) => {
  console.log('onKeycloakEvent', event, error)
}

const onKeycloakTokens = (tokens) => {
  console.log('onKeycloakTokens', tokens)
}
const App = () => {
  return (
    <KeycloakProvider
      keycloak={configureKeycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <Provider store={configStore.store}>
        <PersistGate persistor={configStore.persistor}>
          {/* ConnectedRouter will use the store from Provider automatically */}
          <ConnectedRouter history={configStore.history}>
            {/* <ScrollToTop> */}
            <Helmet>
              <meta charSet="utf-8" />
              <title>{title}</title>
              <meta name="description" content="LOL application" />
            </Helmet>
            <Layout />

            {/* </ScrollToTop> */}
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </KeycloakProvider>
  )
}
export default App
