import React, { memo } from 'react'
import ProfileMenu from './ProfileMenu'
import Clock from 'react-live-clock';

const Index = (props) => {
  return (
    <div className='topbar'>
      <div className='topbar__right'>
        {/*<Language/>*/}
          <ProfileMenu {...props} />
      </div>
      <div className='clearfix' />
      <span className="w-100 text-center font-size-18 font-weight-bold">
        <Clock format={'HH:mm:ss'} ticking={true} />
      </span>
    </div>
  )
}

export default memo(Index)
